import React, { PureComponent } from 'react';
import get from 'lodash/get';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import values from 'lodash/values';
import { ToolSetOptions, ToolSetOptionsItem } from '../../ToolSet';
import { generateSearchPath } from '../../../utils/urlHelpers/listings';
import { allTypes } from '../../../constants/boats';
import Link from '../../SEO/Link';
import { ToolSetTextInput } from '../../ToolSet';
import { typeAheadMatchesValue } from '../../../utils/typeAheadHelper';
import {capitalizeEachWord} from '@dmm/lib-common/lib/formatting';


class FilterClass extends PureComponent {
  state = {
    facets: this.props.facets ? this.props.facets : [],
    multiFacetedBoatTypeClass: this.props.multiFacetedBoatTypeClass ? this.props.multiFacetedBoatTypeClass : {},
    typeAheadText: ''
  }

  handleTypeAhead(value){
    this.setState({ typeAheadText: value });
  }

  updateMultiInput() {
    if (this.props.shouldClearTypeAhead) {
      this.setState({ typeAheadText: '' });
      this.props.setShouldClearTypeAhead(false);
    }
    if (this.props.multiFacetedBoatTypeClass !== undefined) {
      this.setState({ multiFacetedBoatTypeClass: this.props.multiFacetedBoatTypeClass });
    }
    if (this.props.facets !== undefined) {
      this.setState({ facets: this.props.facets });
    }
  }

  toggleType(typeClass, params) {
    if ( params ) {
      const typeClassKey = keys(typeClass)[0];
      const theKeyExists = includes(keys(params.multiFacetedBoatTypeClass), typeClassKey); //is the key in the params?
      if (theKeyExists){
        let arrayOfType = values(params.multiFacetedBoatTypeClass[ typeClassKey ]);
        let newValue = values(typeClass)[0][0];
        let theValueExists = includes(arrayOfType, newValue);
        if ( theValueExists ) {
          let arrayWithoutNewValue = arrayOfType.filter((value) => {
            return value !== newValue;
          });
          return arrayWithoutNewValue.length !== 0 ?
            { multiFacetedBoatTypeClass: {
              ...omit(params.multiFacetedBoatTypeClass, typeClassKey),
              [typeClassKey]: arrayWithoutNewValue
            }
            } :
            { multiFacetedBoatTypeClass: {
              ...omit(params.multiFacetedBoatTypeClass, typeClassKey),
            }
            };

        } else if (allTypes.includes(newValue)) {
          return { multiFacetedBoatTypeClass: {
            ...omit(params.multiFacetedBoatTypeClass, typeClassKey),
            [typeClassKey]: [newValue]
          }
          };
        }
        return { multiFacetedBoatTypeClass: {
          ...omit(params.multiFacetedBoatTypeClass, typeClassKey),
          [typeClassKey]: [...params.multiFacetedBoatTypeClass[typeClassKey], newValue]
        }
        };

      }
      return { multiFacetedBoatTypeClass: {...params.multiFacetedBoatTypeClass, ...typeClass} };

    }
    return { multiFacetedBoatTypeClass: {...typeClass} };

  }

  toggleClass(type, newClass) {
    let newClasses = [];
    let typeArray = get(this.state.multiFacetedBoatTypeClass, type, []);
    if (includes(typeArray, newClass) ) {
      if (this.props.tracking) {
        this.props.tracking.facetRemoved(`class removed - ${newClass}`);
      }
      newClasses = typeArray.filter( (oldclass) => { return oldclass !== newClass;});
    } else {
      if (this.props.tracking) {
        this.props.tracking.facetAdded({ ['multiFacetedBoatTypeClass']: {type: [newClass]} });
      }
      if (allTypes.includes(newClass)) {
        newClasses = [newClass];
      } else {
        newClasses = [...typeArray, newClass];
      }
    }
    if (newClasses.length > 1 && newClasses.some( newClass => allTypes.includes(newClass))) {
      newClasses = newClasses.filter( (oldclass) => { return !allTypes.includes(oldclass);});
    }
    let updatedTypeClasses;
    if (newClasses.length) {
      updatedTypeClasses =  { ...this.state.multiFacetedBoatTypeClass, [type]: newClasses };
    } else {
      updatedTypeClasses = { ...this.state.multiFacetedBoatTypeClass};
      delete updatedTypeClasses[type];
    }

    this.setState(updatedTypeClasses);
    this.props.handleDataChange('multiFacetedBoatTypeClass', updatedTypeClasses);
  }

  getBoatSubType = (heading) => {
    switch (heading) {
    case 'Power Boats':
      return 'power';
    case 'Sailboats':
      return 'sail';
    case 'Small Boats':
      return 'small';
    case 'PWCs':
      return 'pwc';
    }
  };

  componentDidMount() {
    this.updateMultiInput();
  }

  componentDidUpdate() {
    this.updateMultiInput();
  }

  onClickFIlterClass = (e) => e.preventDefault();

  render() {
    const { typeAheadText } = this.state;

    const getAllBoatTypes = () => {
      const nameCounts = {};
      const totalBoatTypes = {
        'Power Boats': {
          count: 0,
          name: 'All Power',
          dashedValue: 'power-all',
          value: 'power-all',
          heading: 'Power Boats'
        },
        'Sailboats': {
          count: 0,
          name: 'All Sail',
          dashedValue: 'sail-all',
          value: 'sail-all',
          heading: 'Sailboats'
        },
        'PWCs': {
          count: 0,
          name: 'All PWC',
          dashedValue: 'power-pwc',
          value: 'power-pwc',
          heading: 'PWCs'
        },
        'Small Boats': {
          count: 0,
          name: 'All Small Boats',
          dashedValue: 'small-all',
          value: 'small-all',
          heading: 'Small Boats'
        }
      };

      this.state.facets.forEach((item) => {
        nameCounts[item.name] = (nameCounts[item.name] || 0) + 1;
        totalBoatTypes[item.heading].count += item.count;
      });
      let allBoatTypes = this.state.facets
        .filter(item => item.value !== 'power-pwc')
        .sort((a,b) => {
          if (a.name < b.name) { return -1;}
          if (a.name > b.name) { return 1; }
          return 0;
        });

      return [...Object.values(totalBoatTypes), ...allBoatTypes].map((item) => {
        const category =  item.value.split('-')[0];
        const capitalizedCategory = capitalizeEachWord(category);
        const subType = this.getBoatSubType(item.heading);
        return {
          ...item,
          name: nameCounts[item.name] > 1 ? `${item.name} (${capitalizedCategory})` : item.name,
          category,
          capitalizedCategory,
          capitalizedValue: capitalizeEachWord(item.value.replace(/-/g, ' ')).replace(/ /g, '-'),
          srpSpecific: `srp-${item.name?.split(' ')[0] === 'All' ? 'all' : 'specific'}-${subType}-link`,
          subType,
          urlValue: item.dashedValue ? item.dashedValue : item.value
        };
      });
    };

    let { position, params } = this.props;
    let id = `class-type-ahead-${position}`;
    let filteredBoatTypes = getAllBoatTypes().filter(item => !typeAheadText ? true : typeAheadMatchesValue(typeAheadText, item.name));

    return (
      <div className="search-filter class" data-e2e="srp-search-filter">
        <ToolSetTextInput
          id={id}
          name={id}
          icon={true}
          value={typeAheadText}
          placeholder={'Search Boat Type'}
          onChange={this.handleTypeAhead.bind(this)}
        />

        <ToolSetOptions>
          {
            filteredBoatTypes.length ?
              <>
                {filteredBoatTypes.filter(val => {
                  return val.count || get(this.state.multiFacetedBoatTypeClass, val.category, []).includes(val.value);
                })
                  .map((val) =>
                    <ToolSetOptionsItem
                      type="checkbox"
                      key={`Class-${val.name}`}
                      id={`${val.capitalizedValue}-${position}`}
                      name={val.capitalizedValue}
                      value={val.value}
                      selected={get(this.state.multiFacetedBoatTypeClass, val.subType, []).includes(val.urlValue)}
                      onClick={this.toggleClass.bind(this,val.subType)}>
                      <Link
                        className={`class-${val.subType}-link`}
                        data-e2e={val.srpSpecific}
                        href={generateSearchPath(this.toggleType({ [val.subType]: [val.urlValue] }, params), params, true)}
                        onClick={this.onClickFIlterClass}>{val.name}</Link>
                    </ToolSetOptionsItem>
                  )}
              </>
              : null
          }
        </ToolSetOptions>
      </div>
    );
  }
}

export default FilterClass;
